import React, { useState } from 'react';

import firebase from '../firebase';
import { isProduction } from '../utils/environmentHelpers';

const NTS_HOSTNAME = isProduction()
    ? "https://www.nts.live"
    : "https://www-integration.nts.live";

function SignInForm() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    function handleSubmit(e) {
        e.preventDefault();
        firebase.signIn(email, password)
            .catch(alert);
    }

    function handleChange(e) {
        const { name, value } = e.target;

        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'password':
                setPassword(value);
                break;
        }
    }

    return (
        <div className="modal-container">
            <div className="modal-container__modal auth-modal mdl-shadow--2dp">
                <div className="auth-modal__logo">
                    <img src="./img/logo-black.svg" alt="NTS Logo" />
                    <h3>Desk</h3>
                </div>
                <form className="auth-modal__form" onSubmit={handleSubmit}>
                    <div className="mdl-textfield" style={{ width: '100%' }}>
                        <input
                            className="mdl-textfield__input"
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange} />
                    </div>
                    <div className="mdl-textfield" style={{ width: '100%' }}>
                        <input
                            className="mdl-textfield__input"
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange} />
                    </div>
                    <button
                        className="mdl-button mdl-button--raised"
                        name="signin">
                        Sign In
                    </button>
                </form>
                <div className="mdl-typography--body-2 mdl-typography--text-center">
                    Log in with your My NTS details<br />or{' '}
                    <a href={`${NTS_HOSTNAME}/forgot-password?embed=true`}
                        rel="noopener noreferrer" target="_blank"
                        className="text-link mdl-color-text--blue-A700">
                        reset your password
                    </a>
                    .
                </div>
            </div>
        </div>
    );
}

export default SignInForm;
