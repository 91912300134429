import React from 'react';

import { ADMIN_PATHNAMES, PATHNAMES } from '../utils/constants';
import CustomerSearch from '../components/CustomerSearch';
import SearchResults from '../components/SearchResults';
import ShowHours from '../components/ShowHours';
import ListenerPicks from '../components/ListenerPicks';

function renderAdminPage(roles, pathname, props) {
    if (!roles.admin) return null;

    if (!ADMIN_PATHNAMES.includes(pathname)) return null;

    if (pathname === PATHNAMES.listenerPicks) return <ListenerPicks />;

    const { searchResult, setSearchResult, customerEmail, setCustomerEmail, setModalOpen } = props;
    return (
        <>
            <CustomerSearch
                pathname={pathname}
                setSearchResult={setSearchResult}
                customerEmail={customerEmail}
                setCustomerEmail={setCustomerEmail} />
            <SearchResults
                pathname={pathname}
                searchResult={searchResult}
                setModalOpen={setModalOpen} />
        </>
    );
}

function renderHostPage(roles, pathname, props) {
    if (!roles.host) return null;

    if (!roles.admin && pathname === PATHNAMES.home) return <ShowHours { ...props } />;

    if (pathname === PATHNAMES.payout) return <ShowHours { ...props } />;

    return null;
}

export {
    renderAdminPage,
    renderHostPage,
};
