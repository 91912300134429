const MODALS = {
    accountEdit: 'accountEdit',
    hostEdit: 'hostEdit',
    payout: 'payout',
    subscriptionLink: 'subscriptionLink',
    accountDelete: 'accountDelete',
};
const PATHNAMES = {
    devices: '/devices',
    home: '/',
    hosts: '/hosts',
    payout: '/payout',
    recommendations: '/recommendations',
    listenerPicks: '/listener-picks',
};
const ADMIN_PATHNAMES = [
    PATHNAMES.devices,
    PATHNAMES.home,
    PATHNAMES.hosts,
    PATHNAMES.recommendations,
    PATHNAMES.listenerPicks,
];
const HOST_PATHNAMES = [
    PATHNAMES.home,
    PATHNAMES.payout,
];

module.exports = {
    ADMIN_PATHNAMES,
    HOST_PATHNAMES,
    MODALS,
    PATHNAMES,
};
