import React, { useEffect, useState } from 'react';

import { MODALS } from '../utils/constants';
import ConfirmDonateButton from './ConfirmDonateButton';
import ConfirmShowHoursButton from './ConfirmShowHoursButton';
import firebase from '../firebase';
import Loader from './Loader';

const REPORTING_PERIOD = `12 months, ending 30 June`;

function formatCurrency(amountCents) {
    const amountPounds = (amountCents / 100).toFixed(2);
    return `£${amountPounds}`;
}

function payoutDateMessage(payoutAt) {
    if(!payoutAt) return "";

    return ` You can expect payment on ${payoutAt.toLocaleDateString(undefined, {month: 'long', day: 'numeric', year: 'numeric'})}.`;
}

function ShowHours({ authUserShows, email, setAuthUserShows, setModalOpen, setShowAliasSelected }) {
    const [loading, setLoading] = useState(true);
    const [confirmedShows, setConfirmedShows] = useState([]);
    const [errorShowAlias, setErrorShowAlias] = useState(null);

    useEffect(getHostShows, []);

    function getHostShows() {
        firebase.getHostShows()
            .then(hostShows => {
                setAuthUserShows(hostShows);
                setTimeout(() => setLoading(false), 600);
            })
            .catch(err => {
                alert(`Something went wrong. ${err.message}`);
            });
    }

    function onClickPayout(showAlias) {
        if (!confirmedShows.includes(showAlias)) {
            return setErrorShowAlias(showAlias);
        }
        setErrorShowAlias(null);
        setModalOpen(MODALS.payout);
        setShowAliasSelected(showAlias);
    }

    function confirmationHandler(showAlias) {
        const filteredShows = confirmedShows.filter(alias => alias !== showAlias);

        if (filteredShows.length === confirmedShows.length) return setConfirmedShows(confirmedShows.concat([showAlias]));

        setConfirmedShows(filteredShows);
    }

    function renderPayoutCta(show) {
        const subject = encodeURIComponent(`Request alternative payout for ${show.name || show.showAlias}`);
        const body = encodeURIComponent(`Show: ${show.name || show.showAlias}\nAmount: ${formatCurrency(show.amountCents)}\nPeriod: ${REPORTING_PERIOD}`);
        return(
            <>
                <p className="text-bold card__footer-title" style={{ marginBottom: '6px' }}>
                    What would you like to do with your payout?
                </p>
                <div style={{ margin: "16px 0 10px" }} >
                    <label htmlFor="confirmation-check">
                        <input onClick={() => confirmationHandler(show.showAlias)} type="checkbox" id="confirmation-check" style={{ marginRight: "5px" }} />
                        <span style={{ verticalAlign: "top" }}>
                            In consideration for the payout, you confirm your agreement to the
                            {" "}
                            <a className="text-link mdl-color-text--blue-A700" target="_blank" href="./documents/host-payout-terms-20230418.pdf">
                                Host Payout Terms
                            </a>
                            {" "}
                            and the
                            {" "}
                            <a className="text-link mdl-color-text--blue-A700" target="_blank"  href="./documents/host-code-of-conduct-20230418.pdf">
                                Code Of Conduct
                            </a>.
                        </span>
                    </label>
                </div>
                {errorShowAlias === show.showAlias && (
                    <div style={{ marginBottom: "16px" }} className="mdl-color-text--red mdl-typography--body-1">
                        You must accept the terms to request the payout
                    </div>
                )}
                <button
                    className="mdl-button mdl-button--raised button-blue button-responsive"
                    onClick={() => onClickPayout(show.showAlias)}
                    style={{ minWidth: '150px' }}>
                    Request Payout
                </button>
                <div style={{ margin: "12px 0" }}>
                    or
                </div>
                <ConfirmDonateButton
                    show={show}
                    onSuccess={getHostShows}>
                    Donate
                </ConfirmDonateButton>
                <span className="card__footer-note">
                    Money donated will go back to NTS so that we can invest in the development of new talent, the Supporters programme, and marketing what we do.
                    <br /><br /><br />
                    If you decide to request the payout, you do not need to send us an invoice for this payment. This process will generate one automatically using the details you provide in the next few steps, and you can download a copy for your records.
                    <br />
                    If you have any questions, please contact us via{' '}
                    <a
                        href={`mailto:hostpayout@ntslive.co.uk?subject=${subject}&body=${body}`}
                        className="text-link mdl-color-text--blue-A700">
                        hostpayout@ntslive.co.uk
                    </a>.
                </span>
            </>
        );
    }

    function renderHoursCta(show) {
        const subject = encodeURIComponent(`Request to change hours reported for ${show.name || show.showAlias}`);
        const body = encodeURIComponent(`Show: ${show.name || show.showAlias}\nHours reported: ${show.hours}\nPeriod: ${REPORTING_PERIOD}`);
        const title = show.hours ? 'Looks good? Hit confirm and we can get started with your payment.' : 'Zero hours reported, no need to confirm payout.';

        return(
            <>
                <p className="text-bold card__footer-title" style={{ marginBottom: '6px' }}>
                    {title}
                </p>
                <ConfirmShowHoursButton
                    hours={show.hours}
                    showAlias={show.showAlias}
                    onSuccess={getHostShows}>
                    Confirm
                </ConfirmShowHoursButton>
                <span className="card__footer-note">
                    If these hours don&#39;t look right and you want to discuss them, please contact us via{' '}
                    <a
                        href={`mailto:hostpayout@ntslive.co.uk?subject=${subject}&body=${body}`}
                        className="text-link mdl-color-text--blue-A700">
                        hostpayout@ntslive.co.uk
                    </a>.
                </span>
            </>
        );
    }

    function renderConfirmed(show) {
        const { invoiceUrl } = show;
        let message = "Thank you! Look out for a follow up email about your payment and banking details in the next few weeks.";
        if (show.donationIsConfirmed) {
            message = "Thanks for choosing to donate to NTS :)";
        } else if (show.payoutConfirmed === "no-account") {
            message = `Thank you! We will be paying you through Wise.${payoutDateMessage(show.payoutAt)} Please look out for an email from noreply@wise.com.`;
        } else if (show.payoutConfirmed) {
            message = `Thank you! We will make your payment to the account ****${show.payoutConfirmed}.${payoutDateMessage(show.payoutAt)}`;
        }

        return (
            <div className="card__confirmation">
                <div style={{ marginRight: '12px' }}>
                    <span
                        className="material-icons material-icons-outlined"
                        style={{ display: 'block', color: '#7ed321' }}>
                        task_alt
                    </span>
                </div>
                <div>
                    <h3
                        className="card__footer-body card__footer-title text-bold"
                        style={{ textTransform: 'uppercase', margin: '0' }}>
                        Confirmed
                    </h3>
                    <p className="card__footer-body" style={{ marginBottom: '0' }}>
                        {message}
                        {invoiceUrl && (
                            <span>
                                {" "}You can{" "}
                                <a className="text-link mdl-color-text--blue-A700" target="_blank" rel="noopener noreferrer" href={invoiceUrl} download>
                                    download
                                </a>{" "}
                                your invoice for your records.
                            </span>
                        )}
                    </p>
                </div>
            </div>
        );
    }

    if (loading) return (
        <div style={{ padding: '48px 0' }}>
            <Loader />
        </div>
    );

    if (!authUserShows.length) return (
        <article className="card">
            <header className="card__account-header">
                <h2 className="card__title">No show found</h2>
            </header>
            <p>We couldn&#39;t find a show linked to the account <strong>{email}</strong>.</p>
            <p>Please reach out to the programming team for help.</p>
        </article>
    );

    return (
        <>
            {authUserShows.map((show, index) => {
                const cardFooterBgColorClass = show.isConfirmed ? 'card__footer--confirmed' : 'card__footer--unconfirmed';
                return (
                    <article className="card card--payout" key={index}>
                        <div className="card__header">
                            <header className="card__account-header">
                                <h2 className="card__title">{show.name || show.showAlias}</h2>
                            </header>
                            <div className="card__results">
                                <div className="card__item-group">
                                    <div>
                                        <div className="card__item-group-key text-bold">Period:</div>
                                        <span className="card__item-group-value">{REPORTING_PERIOD}</span>
                                    </div>
                                    <div>
                                        <div className="card__item-group-key text-bold">Hours:</div>
                                        <span className="card__item-group-value">{show.hours}</span>
                                    </div>
                                    {show.isPayoutPhase &&
                                        <div>
                                            <div className="card__item-group-key text-bold">Amount:</div>
                                            <span className="card__item-group-value">{formatCurrency(show.amountCents)}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`card__footer ${cardFooterBgColorClass}`}>
                            {show.isPayoutPhase && !!show.payoutConfirmed &&
                                renderConfirmed(show)
                            }
                            {show.isPayoutPhase && !show.payoutConfirmed &&
                                renderPayoutCta(show)
                            }
                            {!show.isPayoutPhase && show.hoursConfirmed === show.hours &&
                                renderConfirmed(show)
                            }
                            {!show.isPayoutPhase && show.hoursConfirmed !== show.hours &&
                                renderHoursCta(show)
                            }
                        </div>
                    </article>
                );
            })}
        </>
    );
}

export default ShowHours;
