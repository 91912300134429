import React, { useEffect, useState } from 'react';

import { isAuthorised } from '../utils/authHelpers';
import { MODALS } from '../utils/constants';
import { renderAdminPage, renderHostPage } from '../utils/renderHelpers';
import AccountEditModal from '../components/AccountEditModal';
import AuthorisationError from './AuthorisationError';
import firebase from '../firebase';
import HostEditModal from '../components/HostEditModal';
import Loader from './Loader';
import NavBar from './NavBar';
import PayoutModal from '../components/PayoutModal';
import SignInForm from './SignInForm';
import SubscriptionLinkModal from './SubscriptionLinkModal';
import AccountDeleteModal from './AccountDeleteModal';

import '../scss/styles.scss';

function App() {
    const [loading, setLoading] = useState(true);
    const [authUser, setAuthUser] = useState(null);
    const [pathname, setPathname] = useState('/');
    const [customerEmail, setCustomerEmail] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [authUserShows, setAuthUserShows] = useState([]);
    const [showAliasSelected, setShowAliasSelected] = useState(null);
    const [modalOpen, setModalOpen] = useState(null);

    function onPopstate() {
        setPathname(window.location.pathname);
    }

    useEffect(() => {
        const unlisten = firebase.onAuthStateChanged(user => {
            setAuthUser(user);
            setTimeout(() => setLoading(false), 600);
        });
        return unlisten;
    }, []);
    useEffect(() => {
        onPopstate();
        window.addEventListener('popstate', onPopstate);
        return () => window.removeEventListener('popstate', onPopstate);
    }, []);

    if (loading) return (
        <div className="app-loader">
            <Loader />
        </div>
    );

    if (!authUser) return <SignInForm />;

    const { email, roles } = authUser;
    if (!isAuthorised(roles, pathname)) return <AuthorisationError email={email} />;

    return (
        <div className="app mdl-layout__container">
            <div className="mdl-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
                <NavBar
                    authUser={authUser}
                    pathname={pathname}
                    setPathname={setPathname} />
                <main className="mdl-layout__content">
                    <div className="app__content">
                        <div className="app__content-wrapper">
                            {renderAdminPage(roles, pathname, {
                                searchResult,
                                setSearchResult,
                                customerEmail,
                                setCustomerEmail,
                                setModalOpen,
                            })}
                            {renderHostPage(roles, pathname, {
                                authUserShows,
                                email,
                                setAuthUserShows,
                                setModalOpen,
                                setShowAliasSelected,
                            })}
                        </div>
                    </div>
                </main>
                {modalOpen === MODALS.hostEdit
                    && <HostEditModal
                        searchResult={searchResult}
                        setModalOpen={setModalOpen}
                        setSearchResult={setSearchResult} />}
                {modalOpen === MODALS.accountEdit
                    && <AccountEditModal
                        pathname={pathname}
                        searchResult={searchResult}
                        setModalOpen={setModalOpen}
                        setSearchResult={setSearchResult}
                        setCustomerEmail={setCustomerEmail} />}
                {modalOpen === MODALS.payout
                    && <PayoutModal
                        authUserShows={authUserShows}
                        setAuthUserShows={setAuthUserShows}
                        setModalOpen={setModalOpen}
                        showAliasSelected={showAliasSelected} />}
                {modalOpen === MODALS.subscriptionLink
                    && <SubscriptionLinkModal
                        pathname={pathname}
                        setSearchResult={setSearchResult}
                        searchResult={searchResult}
                        setModalOpen={setModalOpen} />}
                {modalOpen === MODALS.accountDelete
                    && <AccountDeleteModal
                        pathname={pathname}
                        setSearchResult={setSearchResult}
                        searchResult={searchResult}
                        setModalOpen={setModalOpen} />}
            </div>
        </div>
    );
}

export default App;
