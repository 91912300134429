import React from 'react';

function NavLink({ activePathname, href, setPathname, children, className, closeDrawer, ...props }) {
    function handleLinkClick(e) {
        e.preventDefault();
        setPathname(href);
        window.history.pushState({}, '', href);
        closeDrawer();
    }

    const isActiveClass = activePathname === href ? 'active' : '';
    return (
        <a
            className={`${className} ${isActiveClass}`}
            href={href}
            onClick={handleLinkClick}
            {...props}>
            {children}
        </a>
    );
}

export default NavLink;
