import { ADMIN_PATHNAMES, HOST_PATHNAMES } from './constants';

function isAuthorised(roles, pathname) {
    const isAdminPathname = ADMIN_PATHNAMES.includes(pathname);
    const isHostPathname = HOST_PATHNAMES.includes(pathname);

    if ((isAdminPathname && roles.admin)
        || (isHostPathname && roles.host)) return true;

    return false;
}

export {
    isAuthorised,
};
